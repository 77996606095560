<template>
  <div>
    <el-form ref="form" :model="form" label-width="80px" :inline="true">
      <el-form-item label="专家姓名">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">搜索</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="Name" label="姓名" width="180"> </el-table-column>
      <el-table-column prop="Hospital" label="医院"> </el-table-column>
      <el-table-column label="操作" width="130">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click.native.prevent="clickExpert(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="10"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { GetDoctorInfosByPage } from "@/api";

export default {
  name: "Experts",
  data() {
    return {
      form: {
        name: "",
      },
      tableData: [],
      currentPage: 1,
      total: 100,
      TotalPage: 8,
    };
  },
  methods: {
    onSubmit() {
      this.GetDoctorInfosByPageAsync();
    },
    async GetDoctorInfosByPageAsync() {
      const r = await GetDoctorInfosByPage({
        PageIndex: this.currentPage,
        Name: this.form.name,
      });
      if (r.code === 1) {
        this.tableData = r.data.DataList;
        this.total = r.data.totalNum;
        this.TotalPage = r.data.TotalPage;
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.GetDoctorInfosByPageAsync();
    },
    clickExpert(row){
      // console.log(row);
      sessionStorage.PCExpertInfo = JSON.stringify(row)
      this.$router.push({
        name :"ExpertDetail",
        params: {
          doctorId: row.ID
        }
      })
    }
  },
  created() {
    this.GetDoctorInfosByPageAsync();
  },
};
</script>

<style scoped>
.el-table {
  margin-bottom: 20px;
}
</style>